import React from "react";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../Layout/Layout";
import Disqus from "../components/Disqus/Disqus";
import ArticleTags from "../components/ArticleTags/ArticleTags";
import config from "../../data/SiteConfig";
import kebabCase from 'lodash/kebabCase'
import styled from "styled-components"

import "./b16-tomorrow-dark.css";

const Title = styled.h1`
  font-family: "Neuton";
  text-align: center;
  font-size: 3.6rem;
  font-weight: 800;
  line-height: 1.5;
`
const MarkdownWrapper = styled.div`
  font-family: "Neuton";
  font-size: 1.2em;
  padding-bottom: 20px;

  h1 {
    font-size: 2.2em;
  }
  h2 {
    font-size: 2.0em;
  }
  p {
    font-size: 1.2em;
    padding-top: 10px;
    padding-bottom: 10px;
  }
`

export default class PostTemplate extends React.Component {
  render() {
    const { data, pageContext } = this.props;
    const { slug } = pageContext;
    const postNode = data.markdownRemark;
    const post = postNode.frontmatter;
    if (!post.id) {
      post.id = slug;
    }

    return (
      <Layout>
        <div>
          <Helmet>
            <title>{`${post.title} | ${config.siteTitle}`}</title>
          </Helmet>
          <div>
            <header>
              <Title>{post.title}</Title>
                <p>Last Modified: <span>{post.date}</span></p>
                <div>
                  <ArticleTags tags={post.tags}/>
                </div>
            </header>
            <MarkdownWrapper dangerouslySetInnerHTML={{ __html: postNode.html }} />
            <Disqus postNode={postNode} />
          </div>
        </div>
      </Layout>
    );
  }
}

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      timeToRead
      excerpt
      frontmatter {
        title
        cover
        date
        category
        tags
      }
      fields {
        slug
        date
      }
    }
  }
`;
