import React, { Component } from "react";
import { Link, useStaticQuery, graphql } from "gatsby"
import { Container } from "reactstrap";
import styled from "styled-components"

import logo from "../../assets/logo.png";
// import "./MainNav.css";

const NavLinkItem = styled.li`

`

class MainNav extends Component {
  render() {
    const { config, className } = this.props;
    const { copyright } = config;

    return (
        <nav className="navbar navbar-expand-lg navbar-dark sticky-top bg-dark">
            <Container>
                <Link to="/">
                    <img src={logo} height="75px"/>
                </Link>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <ul class="navbar-nav mr-auto">
                        <NavLinkItem className="nav-item">
                            <Link to="/" className="nav-link" activeClassName="active">Home</Link>
                        </NavLinkItem>
                        <NavLinkItem className="nav-item">
                            <Link to="/articles" className="nav-link" activeClassName="active">Articles</Link>
                        </NavLinkItem>
                        {/* <NavLinkItem className="nav-item">
                            <Link to="/projects" className="nav-link" activeClassName="active">Projects</Link>
                        </NavLinkItem> */}
                    </ul>
                </div>
            </Container>
        </nav>
    );
  }
}

export default MainNav;
